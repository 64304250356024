import React from 'react';
import { useCallback } from "react";
import Particles from 'react-particles';
import { loadSlim } from "tsparticles-slim";

const ParticleComponent = () => {
    const particlesInit = useCallback(async engine => {
        console.log(engine);
        
        await loadSlim(engine);
    }, []);

    const particlesLoaded = useCallback(async container => {
        await console.log(container);
    }, []);

    return (
        <Particles
            id="tsparticles" 
            init={particlesInit} 
            loaded={particlesLoaded} 
            options={{ 
                particles: {
                    number: {
                        value: 7,
                        density: {
                            enable: false,
                            value_area: 800,
                        },
                    },
                    shape: {
                        type: 'images',
                        images : [
                            {
                                // react
                                src: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/react/react-original.svg",
                                width: 150,
                                height: 130,
                            },
                            {
                                // django
                                src: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/django/django-plain.svg",
                                width: 150,
                                height: 130,
                            },
                            {
                                // vscode
                                src: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/vscode/vscode-original.svg",
                                width: 150,
                                height: 150,
                            },
                            {
                                // ror
                                src: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/rails/rails-original-wordmark.svg",
                                width: 120,
                                height: 150,
                            },
                            {
                                // ruby
                                src: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/ruby/ruby-original.svg",
                                width: 150,
                                height: 150,
                            },
                            {
                                // python
                                src: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/python/python-original.svg" ,
                                width: 150,
                                height: 150,
                            },
                            {
                                // js
                                src: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/javascript/javascript-original.svg",
                                width: 150,
                                height: 150,
                            },
                            {
                                // tf
                                src: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/tensorflow/tensorflow-original.svg",
                                width: 150,
                                height: 150,
                            },
                            {
                                // pytorch
                                src: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/pytorch/pytorch-original.svg",
                                width: 150,
                                height: 150,
                            },
                        ],
                    },
                    move: {
                        enable: true,
                        speed: 3,
                        direction: 'none',
                        random: true,
                        straight: false,
                        out_mode: 'out',
                        attract: {
                            enable: true,
                            rotateX: 600,
                            rotateY: 1200,
                        },
                    },
                    line_linked: {
                        enable: false,
                    },
                    interactivity: {
                        events: {
                        onhover: {
                            enable: true,
                            mode: 'repulse',
                        },
                        },
                        modes: {
                            repulse: {
                                distance: 200,
                                duration: 0.4,
                            },
                        },
                    }, 
                    size: {
                        value: 15,
                        random: true,
                        anim: {
                            enable: true,
                            speed: 3,
                            size_min: 11,
                            sync: false
                        }
                    }
                },
                detectRetina: true,
            }}
        />
  );
};

export default ParticleComponent;